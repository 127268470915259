<template>
  <div>
    <DataTable
      :value="files"
      sortField="date"
      :sortOrder="-1"
      tableStyle="min-width: 50rem"
      v-if="files.length"
    >
      <Column sortable="true" field="fileName" header="File Name"></Column>
      <Column sortable="true" field="createdBy" header="Created By"></Column>
      <Column sortable="true" field="approvedBy" header="Approved By"></Column>
      <Column sortable="true" field="date" header="Date">
        <template #body="slotProps">
          <span>{{ formatDate(slotProps?.data?.date) }}</span>
        </template>
      </Column>
      <Column field="link" header="Download">
        <template #body="slotProps">
          <a
            v-if="slotProps?.data?.signedUrl"
            class="cursor-pointer text-blue-500 underline"
            target="_blank"
            :href="slotProps.data.signedUrl"
            :download="slotProps.data.fileName"
          >
            View & Download
          </a>
          <div v-else class="text-gray-400">No available file</div>
        </template>
      </Column>
      <Column field="version" header="Version">
        <template #body="slotProps">
          <span
            v-if="slotProps?.data?.isLatestVersion"
            class="text-xs font-bold bg-blue-500 text-flame-white rounded px-3 py-1"
            >Latest</span
          >
        </template>
      </Column>
    </DataTable>
    <div v-else class="flex justify-center items-center">
      <div class="flex flex-col justify-center items-center">
        <font-awesome-icon icon="magnifying-glass" class="text-8xl text-surface-200 mb-8" />
        <h1>Nothing found.</h1>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useFileService } from '@/components/composables/UseFileService'

const files = ref([])
const project = inject('project')

const { getFileKeys } = useFileService()

const formatDate = (timestamp) => {
  const date = new Date(timestamp)
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  return date.toLocaleString('en-US', options)
}

onMounted(async () => {
  const clientId = project.value.client_id
  const quoteId = project.value.quote_id
  const changeOrders = project.value.aoChangeOrders
  const prefix = `${clientId}/${quoteId}/`
  const keys = await getFileKeys(prefix)

  const signedUrls = await keys.map(
    (key) => `${import.meta.env.VITE_S3_APPROVALS_BUCKET_ENDPOINT}/${key}`
  )

  changeOrders.forEach((order) => {
    const {
      approver_fname,
      approver_lname,
      creator_fname,
      creator_lname,
      approval_time,
      change_order_id,
      change_order_name
    } = order

    const approvedBy = `${approver_fname || ''} ${approver_lname || ''}`.trim() || '--'
    const createdBy = `${creator_fname || ''} ${creator_lname || ''}`.trim() || '--'

    const fileKey = keys.find((key) => key.includes(`${change_order_id}`))
    const signedUrl = fileKey ? signedUrls[keys.indexOf(fileKey)] : null

    files.value.push({
      fileName: `${change_order_name}-${change_order_id}`,
      createdBy,
      approvedBy,
      date: approval_time,
      signedUrl,
      isLatestVersion: false
    })
  })

  markLatestVersion()
})

const markLatestVersion = () => {
  files.value.sort((a, b) => new Date(b.date) - new Date(a.date))
  if (files.value.length > 0) {
    files.value[0].isLatestVersion = true
  }
}
</script>
