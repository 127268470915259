<template>
  <div class="mt-8">
    <h1>Estimation History</h1>
    <FilesTable />
  </div>
</template>

<script setup>
import { provide, computed } from 'vue'
import { useStore } from 'vuex'
import FilesTable from '@/components/ui/FilesTable.vue'

const store = useStore()
const project = computed(() => store.state.session.quote)
provide('project', project)
</script>
